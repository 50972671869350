import request from '@/utils/request'
export function fetchApplyList (data) {
  return request({
    url: '/applyAccount/list',
    method: 'POST',
    data
  })
}
export function updateExpireDate (data) {
  return request({
    url: '/applyAccount/updateExpireDate',
    method: 'POST',
    data
  })
}
