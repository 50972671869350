<template>
  <div>
    <div style="background: #FFFFFF; margin-bottom: 10px; padding: 20px; display: grid; grid-template-columns: repeat(4, auto); column-gap: 20px">
      <div style="display: grid; grid-template-columns: 60px auto; align-items: center;">
        <div style="color: rgba(0,0,0,0.7); font-size: 15px">联系人</div>
        <el-input size="small" v-model="linkName" placeholder="请输入联系人姓名"></el-input>
      </div>
      <div style="display: grid; grid-template-columns: 75px auto; align-items: center;">
        <div style="color: rgba(0,0,0,0.7); font-size: 15px">联系方式</div>
        <el-input size="small" v-model="email" placeholder="请输入邮箱地址或者手机号"></el-input>
      </div>
      <div style="display: grid; grid-template-columns: 45px auto; align-items: center;">
        <div style="color: rgba(0,0,0,0.7); font-size: 15px">公司</div>
        <el-input size="small" v-model="companyName" placeholder="请输入公司名称"></el-input>
      </div>
      <div>
        <el-button @click="search" size="small" type="primary">查询</el-button>
        <el-button @click="page = 1, size = 10, linkName = '', email = '', companyName = '', search()" size="small" type="warning">重置</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="applyList"
      border
      style="width: 100%">
      <el-table-column
        prop="linkName"
        align="center"
        width="100"
        label="联系人">
      </el-table-column>
      <el-table-column
        prop="position"
        width="200"
        label="公司">
      </el-table-column>
      <el-table-column
        prop="email"
        width="200"
        label="邮箱">
      </el-table-column>
      <el-table-column
        prop="phone"
        width="120"
        label="手机号">
      </el-table-column>
      <el-table-column
        prop="openId"
        label="OPENID">
      </el-table-column>
      <el-table-column
        prop="userId"
        label="USERID">
      </el-table-column>
      <el-table-column
        width="70"
        align="center"
        label="关注">
        <template scope="{ row }">
          <span v-if="row.isFollow === 1" style="color: green">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        width="70"
        align="center"
        label="过期">
        <template scope="{ row }">
          <span v-if="row.isExpire === 1" style="color: red">是</span>
          <span v-else style="color: green">否</span>
        </template>
      </el-table-column>
      <el-table-column
        width="180"
        align="center"
        label="过期时间">
        <template scope="{ row }">
          {{ row.expireDate.substring(0, 19) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="250px"
        label="操作">
        <template scope="{ row }">
          <el-button type="primary" size="mini" @click="changeExpireDate(row)">修改过期时间</el-button>
          <el-button type="success" size="mini" @click="copyAccount(row)">复制账号密码</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: right; margin-top: 0px; background: #FFFFFF; padding: 10px"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-sizes="[10, 30, 50, 100, 200, 300, 500]"
      :page-size="size"
      layout="sizes, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog
      title="账号过期时间设置"
      :visible.sync="changeExpireDialog"
      width="20%"
      :before-close="handleClose">
      <div class="block" style="display: grid; grid-template-columns: 70px auto; align-items: center">
        <div>
          <span class="demonstration" style="margin-right: 10px">过期时间</span>
        </div>
        <div>
        <el-date-picker
          style="width: 100%"
          v-model="expireDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions">
        </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="changeExpireDialog = false">取 消</el-button>
    <el-button size="small" type="primary" @click="setExpireDate">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchApplyList, updateExpireDate } from '@/api/apply'
export default {
  name: 'name',
  data () {
    return ({
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 0)
            date.setHours(23, 59, 59, 999)
            picker.$emit('pick', date)
          }
        }, {
          text: '明天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 1)
            date.setHours(23, 59, 59, 999)
            picker.$emit('pick', date)
          }
        }, {
          text: '三天后',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 3)
            date.setHours(23, 59, 59, 999)
            picker.$emit('pick', date)
          }
        }, {
          text: '七天后',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
            date.setHours(23, 59, 59, 999)
            picker.$emit('pick', date)
          }
        }, {
          text: '三十天后',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30)
            date.setHours(23, 59, 59, 999)
            picker.$emit('pick', date)
          }
        }]
      },
      loading: true,
      applyList: [],
      total: 0,
      size: 10,
      page: 1,
      linkName: '',
      email: '',
      companyName: '',
      expireDate: '',
      userId: '',
      changeExpireDialog: false
    })
  },
  mounted () {
    this.fetchApplyList()
  },
  methods: {
    async changeExpireDate (row) {
      this.expireDate = row.expireDate.substring(0, 19)
      this.userId = row.userId
      this.changeExpireDialog = true
    },
    async setExpireDate () {
      const res = await updateExpireDate({
        userId: this.userId,
        expireDate: this.expireDate
      })
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.changeExpireDialog = false
        this.fetchApplyList()
      } else {
        this.$message.error(res.msg)
      }
    },
    handleClose () {
      this.expireDate = ''
      this.userId = ''
      this.changeExpireDialog = false
    },
    copyAccount (row) {
      if (!row.account) {
        this.$message.error('该用户没有生成系统账号，请联系管理员处理该问题')
        return
      }
      var content = '您好，' + row.linkName + '\n您的账号为：' + row.account + '\n您的密码为：' + row.password + '\n系统登录地址：https://yq.congting.com'
      if (window.clipboardData) {
        window.clipboardData.setData('text', content)
      } else {
        (function (content) {
          document.oncopy = function (e) {
            e.clipboardData.setData('text', content)
            e.preventDefault()
            document.oncopy = null
          }
        })(content)
        document.execCommand('Copy')
      }
      this.$message.success('该用户的账号和密码复制成功')
    },
    async handleSizeChange (val) {
      this.size = val
      this.page = 1
      await this.fetchApplyList()
    },
    async handleCurrentChange (val) {
      this.page = val
      await this.fetchApplyList()
    },
    async search () {
      this.page = 1
      await this.fetchApplyList()
    },
    async fetchApplyList () {
      this.loading = true
      const res = await fetchApplyList({
        page: this.page,
        size: this.size,
        linkName: this.linkName,
        email: this.email,
        companyName: this.companyName
      })
      this.loading = false
      if (res.code === 0) {
        this.applyList = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('查询时发生了错误')
        this.applyList = []
        this.total = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
